import $ from 'cash-dom'

window.isBlank = (str) ->
  (!str || /^\s*$/.test(str))

window.ReCaptchaCallbackV3 = ->
  window.grecaptcha.ready ->
    reCaptchaV3SiteKey = $('script#bt-ext-recaptcha').data('recaptcha-v3-site-key')
    reCaptchaV2SiteKey = $('script#bt-ext-recaptcha').data('recaptcha-v2-site-key')
    reCaptchaAction = $('body').data('recaptcha-action')

    if !isBlank(reCaptchaV3SiteKey) && !isBlank(reCaptchaAction)
      window.grecaptcha.execute(reCaptchaV3SiteKey, { action: reCaptchaAction }).then (token) ->
        $('input.bt-ext-recaptcha-token').val(token)

    if $('#bt-ext-recaptcha-tick-box').length > 0
      grecaptcha.render('bt-ext-recaptcha-tick-box', { sitekey: reCaptchaV2SiteKey })