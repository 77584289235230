import $ from 'cash-dom'

DEFAULTS_AGREE_ALL = {
  disableGoogleAnalytcs: 0,
}
DEFAULTS_DECLINE_ALL = {
  disableGoogleAnalytcs: 1,
}
ONE_YEAR_IN_DAYS = 365

window.cookieKey = ->
  '_backlinktrack_prefs'

window.isCookiePreferenceChosen = ->
  consentCookie = getCookiePreferences()
  consentCookie != null && consentCookie != '' && consentCookie != undefined

window.checkCookiePreferencesChosen = ->
  if isCookiePreferenceChosen()
    $('#consent-container').addClass('hidden')
  else
    $('#consent-container').removeClass('hidden')

window.setCookiePreferences = (jsonValue, expiryDays) ->
  cookieName = cookieKey()
  expiryDate = new Date
  expiryDate.setDate expiryDate.getDate() + expiryDays
  cookieValue = escape(JSON.stringify(jsonValue)) + (if expiryDays == null then '' else '; expires=' + expiryDate.toUTCString())
  document.cookie = cookieName + '=' + cookieValue + '; path=/'
  $('#consent-container').removeClass('block')
  $('#consent-container').addClass('hidden')
  window.cookiePreferences = jsonValue

window.getCookiePreferences = ->
  currentCookieName = undefined
  currentCookieValue = undefined
  allBrowserCookies = document.cookie.split(';')
  browserCookieIndex = 0
  while browserCookieIndex < allBrowserCookies.length
    currentCookieName = allBrowserCookies[browserCookieIndex].substr(0, allBrowserCookies[browserCookieIndex].indexOf('='))
    currentCookieValue = allBrowserCookies[browserCookieIndex].substr(allBrowserCookies[browserCookieIndex].indexOf('=') + 1)
    currentCookieName = currentCookieName.replace(/^\s+|\s+$/g, '')
    if currentCookieName == cookieKey()
      return JSON.parse(unescape(currentCookieValue))
    browserCookieIndex++

window.areEqualCaseInsensitive = (string1, string2) ->
  string1.toLowerCase().includes(string2.toLowerCase())

window.parseAsInt = (str) ->
  if str == "0" || str == 0
    return 0
  else
    return 1

window.disableGoogleAnalyticsIfRequested = () ->
  cookiePreferences = getCookiePreferences()
  gaTrackingId = $('body').data('google-analytics-id')

  if cookiePreferences and cookiePreferences.disableGoogleAnalytcs != 0
    window['ga-disable-' + gaTrackingId] = true

window.toBoolVal = (bool) ->
  if bool
    return 1
  else
    return 0

window.setUpAdDataPreferencesForm = () ->
  cookiePreferences = getCookiePreferences()

  if cookiePreferences
    $('input#bt-ext-data-prefs_disable_google_analytics').prop('checked', cookiePreferences.disableGoogleAnalytcs)

  $('#bt-ext-data-prefs').on 'click', (event) ->
    event.preventDefault()
    setCookiePreferences({
      disableGoogleAnalytcs: toBoolVal($('#bt-ext-data-prefs_disable_google_analytics').prop('checked')),
    }, ONE_YEAR_IN_DAYS)


$(document).ready ->
  checkCookiePreferencesChosen()
  disableGoogleAnalyticsIfRequested()

  window.cookiePreferences = getCookiePreferences()

  setUpAdDataPreferencesForm()

  $('button#bt-ext-accept-personalized').on 'click', (event) ->
    event.preventDefault()
    setCookiePreferences(DEFAULTS_AGREE_ALL, ONE_YEAR_IN_DAYS)

  $('button#bt-ext-reject-personalized').on 'click', (event) ->
    event.preventDefault()
    setCookiePreferences(DEFAULTS_DECLINE_ALL, ONE_YEAR_IN_DAYS)
