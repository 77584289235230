import $ from 'cash-dom'

window.bindBrowseMenu = ->
  show = ->
    $('.bt-ext-browse-menu-reveal-panel').removeClass('hidden')
    $('.bt-ext-browse-menu-blur-panel').removeClass('hidden')

  hide = ->
    $('.bt-ext-browse-menu-reveal-panel').addClass('hidden')
    $('.bt-ext-browse-menu-blur-panel').addClass('hidden')

  $('.bt-ext-browse-menu-btn-reveal').on 'click.browse_menu', -> show()
  $('.bt-ext-browse-menu-btn-close').on 'click.browse_menu', -> hide()
  $('.bt-ext-browse-menu-reveal-panel a').on 'click.browse_menu', -> hide()

window.bindHelpMenu = ->
  show = ->
    $('.bt-ext-help-menu-reveal-panel').removeClass('hidden')
    $('.bt-ext-help-menu-blur-panel').removeClass('hidden')
    $('.bt-ext-help-menu-btn-reveal').addClass('hidden')
    $('.bt-ext-help-menu-btn-close').removeClass('hidden')

  hide = ->
    $('.bt-ext-help-menu-reveal-panel').addClass('hidden')
    $('.bt-ext-help-menu-blur-panel').addClass('hidden')
    $('.bt-ext-help-menu-btn-reveal').removeClass('hidden')
    $('.bt-ext-help-menu-btn-close').addClass('hidden')

  $('.bt-ext-help-menu-btn-reveal').on 'click.help_menu', -> show()
  $('.bt-ext-help-menu-btn-close').on 'click.help_menu', -> hide()

window.bindBackendMenu = ->
  show = ->
    $('.bt-ext-backend-menu-reveal-panel').removeClass('hidden')

  hide = ->
    $('.bt-ext-backend-menu-reveal-panel').addClass('hidden')

  $('.bt-ext-backend-menu-btn-reveal').on 'click.backend_menu', -> show()
  $('.bt-ext-backend-menu-btn-close').on 'click.backend_menu', -> hide()

$(document).ready ->
  bindBrowseMenu()
  bindHelpMenu()
  bindBackendMenu()
