import $ from 'cash-dom'

window.lazyscriptDelay = (lazyscriptElem) ->
  $(lazyscriptElem).data('lazyscript-delay')

window.lazyscriptLoad = (lazyscriptElem) ->
  scriptElement = document.createElement('script')
  scriptElement.type = 'text/javascript'

  $.each lazyscriptElem.attributes, (_index, attribute) ->
    attributeName = attribute.name
    if attributeName.startsWith('data') && !attributeName.startsWith('data-lazyscript')
      attributeNameWithoutPrefix = attributeName.replace('data-', '')

      attributeKey = attributeNameWithoutPrefix
      attributeValue = $(lazyscriptElem).data(attributeNameWithoutPrefix)

      scriptElement.setAttribute(attributeKey, attributeValue)

  $('body').append(scriptElement)

window.LAZYSCRIPT_TRIGGERED = false

window.lazyscriptLoadForAll = ->
  if window.LAZYSCRIPT_TRIGGERED
    $(document).off('mousemove.lazyscript')
    $(document).off('click.lazyscript')
    $(document).off('scroll.lazyscript')
    $(document).off('keypress.lazyscript')
    return

  window.LAZYSCRIPT_TRIGGERED = true

  $.each $('.bt-ext-lazyscript'), (_index, lazyscriptElem) ->
    delay = lazyscriptDelay(lazyscriptElem)
    if delay > 0
      setTimeout (-> lazyscriptLoad(lazyscriptElem)), delay

$(document).ready ->
  $(document).on('mousemove.lazyscript', lazyscriptLoadForAll)
  $(document).on('click.lazyscript', lazyscriptLoadForAll)
  $(document).on('scroll.lazyscript', lazyscriptLoadForAll)
  $(document).on('keypress.lazyscript', lazyscriptLoadForAll)

  $.each $('.bt-ext-lazyscript'), (_index, lazyscriptElem) ->
    if lazyscriptDelay(lazyscriptElem) < 1
      lazyscriptLoad(lazyscriptElem)
